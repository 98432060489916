export const SolicitudValidator = (form) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 digitos",
    };
  }
  if (!/Q1417/i.test(form.Folio.trim())) {
    return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  }
  if (
    form.Nombre === "" ||
    form.Paterno === "" ||
    form.idEstadoCivil === "" ||
    form.idEntidadNacimiento === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }

  if (
    form.NecesidadSolicitante.trim() === "" ||
    form.CostoNecesidad === "" ||
    form.CostoNecesidad < 0
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive.trim() == "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorCalentadorN = (form) => {
  if (form.CURP.trim() === "") {
    return {
      error: true,
      seccion: 0,
      message: "Debe agregar el CURP del solicitante",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  if (form.FechaINE !== "") {
    let flag = false;
    if (isNaN(form.FechaINE)) {
      flag = true;
    }
    if (form.FechaINE.toString().length !== 4) {
      flag = true;
    } else {
      let fecha_actual = new Date();
      let aa_actual = fecha_actual.getFullYear();

      if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
        flag = true;
      }
    }
    if (flag) {
      return {
        error: true,
        seccion: 1,
        message: "La vigencia de la Identificación Oficial no es válida",
      };
    }
  } else {
    let flag = false;
    if (form.ArchivosClasificacion.length > 0) {
      let tarjetaContigoSi = form.ArchivosClasificacion.find(
        (element) => element == 13
      );
      if (tarjetaContigoSi == undefined) {
        flag = true;
      }
    } else {
      flag = true;
    }

    if (flag) {
      return {
        error: true,
        seccion: 1,
        message:
          "Debe agregar la vigencia de la Identificación o cargar la Tarjeta Contigo Si con su clasificación correcta",
      };
    }
  }

  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CPVive.toString().length !== 5) {
    return {
      error: true,
      seccion: 5,
      message: "El código postal debe ser de 5 dígitos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }

  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }

  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }

  if (form.Enlace.trim() === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe agregar el enlace",
    };
  }

  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 7,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element == 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la identificación oficial solo una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element == 4);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el CURP solo una vez",
      };
    }

    let curp = form.ArchivosClasificacion.filter((element) => element == 6);
    if (curp.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el comprobante de domicilio solo una vez",
      };
    }

    let acuse = form.ArchivosClasificacion.filter((element) => element == 5);
    if (acuse.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el acuse solo una vez",
      };
    }

    let evidencia = form.ArchivosClasificacion.filter(
      (element) => element == 12
    );
    if (evidencia.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue las evidencias PDF solo una vez",
      };
    }

    let formato = form.ArchivosClasificacion.filter((element) => element == 10);
    if (formato.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el formato del programa solo una vez",
      };
    }

    let tarjeta = form.ArchivosClasificacion.filter((element) => element == 13);
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la tarjeta Contigo Si solo una vez",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const ValidatorCalentador = (form) => {
  if (form.CURP.trim() === "") {
    return {
      error: true,
      seccion: 0,
      message: "Debe agregar el CURP del solicitante",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  if (form.FechaINE !== "") {
    let flag = false;
    if (isNaN(form.FechaINE)) {
      flag = true;
    }
    if (form.FechaINE.toString().length !== 4) {
      flag = true;
    } else {
      let fecha_actual = new Date();
      let aa_actual = fecha_actual.getFullYear();

      if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
        flag = true;
      }
    }
    if (flag) {
      return {
        error: true,
        seccion: 1,
        message: "La vigencia de la Identificación Oficial no es válida",
      };
    }
  } else {
    return {
      error: true,
      seccion: 1,
      message: "Debe agregar la vigencia de la Identificación Oficial",
    };
  }

  if (
    form.Calle.trim() === "" ||
    form.NumExt.trim() === "" ||
    form.CP === "" ||
    form.Colonia.trim() === "" ||
    form.idMunicipio === "" ||
    form.idLocalidad === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CP.toString().length !== 5) {
    return {
      error: true,
      seccion: 5,
      message: "El código postal debe ser de 5 dígitos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }

  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }

  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }

  if (form.CURPTutor.trim() !== "" && form.idParentescoTutor === "") {
    return {
      error: true,
      seccion: 3,
      message: "Debe agregar el parentesco con el acompañante",
    };
  }

  if (form.Enlace.trim() === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe agregar el enlace",
    };
  }

  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 7,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element === 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la identificación oficial solo una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element === 4);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el CURP solo una vez",
      };
    }

    let curp = form.ArchivosClasificacion.filter((element) => element === 6);
    if (curp.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el comprobante de domicilio solo una vez",
      };
    }

    let acuse = form.ArchivosClasificacion.filter((element) => element === 5);
    if (acuse.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el acuse solo una vez",
      };
    }

    let evidencia = form.ArchivosClasificacion.filter(
      (element) => element === 12
    );
    if (evidencia.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue las evidencias PDF solo una vez",
      };
    }

    let formato = form.ArchivosClasificacion.filter(
      (element) => element === 10
    );
    if (formato.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el formato del programa solo una vez",
      };
    }

    let tarjeta = form.ArchivosClasificacion.filter(
      (element) => element === 13
    );
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la tarjeta Contigo Si solo una vez",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorYoPuedoN = (form) => {
  if (form.CURP.trim() === "") {
    return {
      error: true,
      seccion: 0,
      message: "Debe agregar el CURP del solicitante",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  if (form.FechaINE !== "") {
    let flag = false;
    if (isNaN(form.FechaINE)) {
      flag = true;
    }
    if (form.FechaINE.toString().length !== 4) {
      flag = true;
    } else {
      let fecha_actual = new Date();
      let aa_actual = fecha_actual.getFullYear();

      if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
        flag = true;
      }
    }
    if (flag) {
      return {
        error: true,
        seccion: 1,
        message: "La vigencia de la Identificación Oficial no es válida",
      };
    }
  } else {
    let flag = false;
    if (form.ArchivosClasificacion.length > 0) {
      let tarjetaContigoSi = form.ArchivosClasificacion.find(
        (element) => element == 13
      );
      if (tarjetaContigoSi == undefined) {
        flag = true;
      }
    } else {
      flag = true;
    }

    if (flag) {
      return {
        error: true,
        seccion: 1,
        message:
          "Debe agregar la vigencia de la Identificación o cargar la Tarjeta Contigo Si con su clasificación correcta",
      };
    }
  }

  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CPVive.toString().length !== 5) {
    return {
      error: true,
      seccion: 5,
      message: "El código postal debe ser de 5 dígitos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }

  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }

  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }

  if (form.idEnlace === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe agregar el enlace",
    };
  }

  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 7,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element == 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la identificación oficial solo una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element == 4);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el CURP solo una vez",
      };
    }

    let curp = form.ArchivosClasificacion.filter((element) => element == 6);
    if (curp.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el comprobante de domicilio solo una vez",
      };
    }

    let acuse = form.ArchivosClasificacion.filter((element) => element == 5);
    if (acuse.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el acuse solo una vez",
      };
    }

    let evidencia = form.ArchivosClasificacion.filter(
      (element) => element == 12
    );
    if (evidencia.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue las evidencias PDF solo una vez",
      };
    }

    let formato = form.ArchivosClasificacion.filter((element) => element == 10);
    if (formato.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue el formato del programa solo una vez",
      };
    }

    let tarjeta = form.ArchivosClasificacion.filter((element) => element == 13);
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Cargue la tarjeta Contigo Si solo una vez",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorYoPuedo = (form) => {
  if (
    !form.FechaSolicitud ||
    form.FechaSolicitud === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 caracteres",
    };
  }
  if (!/Q0256/i.test(form.Folio.trim())) {
    return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  }
  if (
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.idEntidadNacimiento === "" ||
    form.idEstadoCivil === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0 ||
    form.idSituacionActual === ""
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  if (
    form.Edad < 18 &&
    (form.idParentescoTutor === "" ||
      form.NombreTutor.trim() === "" ||
      form.PaternoTutor.trim() === "" ||
      form.MaternoTutor.trim() === "" ||
      form.MaternoTutor.trim() === "" ||
      form.FechaNacimientoTutor.trim() === "" ||
      form.EdadTutor === "" ||
      form.EdadTutor < 18 ||
      form.SexoTutor === "" ||
      form.idEntidadNacimientoTutor === "" ||
      form.CURPTutor.trim() === "" ||
      form.TelefonoTutor.trim() === "")
  ) {
    return {
      error: true,
      seccion: 3,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.CURPTutor.trim() !== "" && form.CURPTutor.trim().length !== 18) {
    return {
      error: true,
      seccion: 3,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (
    form.TelefonoTutor.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelefonoTutor.trim().replace(/\D/g, "")
    )
  ) {
    return { error: true, seccion: 3, message: "Teléfono no valido" };
  }
  if (
    form.CorreoTutor.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.CorreoTutor.trim()
    )
  ) {
    return { error: true, seccion: 3, message: "Correo no valido" };
  }
  if (form.NecesidadSolicitante.trim() === "" || form.CostoNecesidad === "") {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.idEnlace === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe seleccionar el enlace",
    };
  }
  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorDiagnosticos = (form) => {
  if (
    !form.FechaSolicitud ||
    form.FechaSolicitud === "" ||
    form.CURP.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  // if (form.Folio.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 0,
  //     message: "El Folio debe contener 18 caracteres",
  //   };
  // }
  // if (!/Q0256/i.test(form.Folio.trim())) {
  //   return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  // }
  if (
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.idEntidadNacimiento === "" ||
    form.idEstadoCivil === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0 ||
    form.idSituacionActual === ""
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  if (
    form.Celular.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }

  if (form.NecesidadSolicitante.trim() === "" || form.CostoNecesidad === "") {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  // if (form.idEnlace === "") {
  //   return {
  //     error: true,
  //     seccion: 6,
  //     message: "Debe seleccionar el enlace",
  //   };
  // }
  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorProyectos = (form) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 digitos",
    };
  }
  if (!/Q3075/i.test(form.Folio.trim())) {
    return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  }
  if (
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  if (
    form.NecesidadSolicitante.trim() === "" ||
    form.CostoNecesidad === "" ||
    form.CostoNecesidad < 0
  ) {
    return {
      error: true,
      seccion: 3,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CostoNecesidad === 0) {
    return {
      error: true,
      seccion: 3,
      message: "El costo debe ser mayor a cero",
    };
  }
  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive.trim() == "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CPVive.toString().length !== 5) {
    return {
      error: true,
      seccion: 4,
      message: "El código postal debe ser de 5 dígitos",
    };
  }
  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorD = (form) => {
  if (
    //form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" //||
    //form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  // if (form.Folio.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 0,
  //     message: "El Folio debe contener 18 digitos",
  //   };
  // }

  // if (!/Q3450/i.test(form.Folio.trim())) {
  //   return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  // }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  // if (
  //   form.FechaNacimiento === "" ||
  //   form.idEntidadNacimiento == "" ||
  //   form.idEstadoCivil === "" ||
  //   form.idSituacionActual === "" ||
  //   form.idParentescoJefeHogar === "" ||
  //   form.Edad === "" ||
  //   form.Edad < 1 ||
  //   form.NumHijos === "" ||
  //   form.NumHijos < 0 ||
  //   form.NumHijas === "" ||
  //   form.NumHijas < 0
  // ) {
  //   return {
  //     error: true,
  //     seccion: 1,
  //     message: "Uno o más datos obligatorios están vacíos o son incorrectos",
  //   };
  // }

  if (form.FechaINE !== "") {
    if (isNaN(form.FechaINE)) {
      return {
        error: true,
        seccion: 1,
        message:
          "El año de vencimiento de la Identificación Oficial no es válido",
      };
    }
    if (form.FechaINE.toString().length !== 4) {
      return {
        error: true,
        seccion: 1,
        message:
          "El año de vencimiento de la Identificación Oficial no es válido",
      };
    } else {
      let fecha_actual = new Date();
      let aa_actual = fecha_actual.getFullYear();
      //if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
      if (parseInt(form.FechaINE) < 2022) {
        return {
          error: true,
          seccion: 1,
          message: "La vigencia de la Identificación Oficial no es válida",
        };
      }
    }
  } else {
    let flag = false;
    let flagFolio = false;

    if (form.ArchivosClasificacion.length > 0) {
      let tarjetaContigoSi = form.ArchivosClasificacion.find(
        (element) => element == 13
      );
      if (tarjetaContigoSi == undefined) {
        flag = true;
      }
    } else {
      flag = true;
    }

    if (flag) {
      return {
        error: true,
        seccion: 1,
        message:
          "Debe agregar la vigencia de la Identificación o cargar la Tarjeta Contigo Si con su clasificación correcta",
      };
    } else {
      if (form.FolioTarjetaImpulso.trim() === "") {
        return {
          error: true,
          seccion: 0,
          message: "Debe agregar el folio de la Tarjeta Contigo Si",
        };
      }
    }
    // if (form.FolioTarjetaImpulso.trim() === "") {
    // return {
    //   error: true,
    //   seccion: 1,
    //   message:
    //     "Debe agregar el año de vencimiento de la Identificación Oficial",
    // };
    //}
  }

  // if (parseInt(form.Edad) !== moment().diff(form.FechaNacimiento, "years")) {
  //   return {
  //     error: true,
  //     seccion: 1,
  //     message: "La edad no coincide con el año de nacimiento",
  //   };
  // }

  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" //||
    //form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CPVive.toString().length !== 5) {
    return {
      error: true,
      seccion: 4,
      message: "El código postal debe ser de 5 dígitos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }

  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  // if (form.TelRecados.trim() !== "") {
  //   if (
  //     !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
  //       form.TelRecados.trim().replace(/\D/g, "")
  //     )
  //   ) {
  //     return {
  //       error: true,
  //       seccion: 2,
  //       message: "Teléfono de recados no valido",
  //     };
  //   }
  // }

  // if (
  //   form.NecesidadSolicitante.trim() === "" ||
  //   form.CostoNecesidad === "" ||
  //   form.CostoNecesidad < 0
  // ) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "Uno o más datos obligatorios están vacíos",
  //   };
  // }
  // if (form.Latitud.trim() != "") {
  //   if (isNaN(form.Latitud)) {
  //     return {
  //       error: true,
  //       seccion: 3,
  //       message: "El formato de Latitud es inválido",
  //     };
  //   }
  // }

  // if (form.IngresoMensual !== "") {
  //   if (isNaN(form.IngresoMensual)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El ingreso mensual no es válido",
  //     };
  //   } else {
  //     if (form.IngresoMensual % 1 != 0) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message: "El ingreso mensual no debe contener decimales",
  //       };
  //     }
  //   }
  // } else {
  //   return {
  //     error: true,
  //     seccion: 5,
  //     message: "Debe agregar el ingreso mensual del solicitante",
  //   };
  // }

  // if (form.OtrosIngresos !== "") {
  //   if (isNaN(form.OtrosIngresos)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "Otros ingresos no es válido",
  //     };
  //   } else {
  //     if (form.OtrosIngresos % 1 != 0) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message: "Los otros ingresos no debe contener decimales",
  //       };
  //     }
  //   }
  // }

  // if (form.TotalIngreso !== "") {
  //   if (isNaN(form.TotalIngreso)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El total de ingresos mensual no es válido",
  //     };
  //   } else {
  //     if (form.TotalIngreso % 1 != 0) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message: "El total de ingresos mensual no debe contener decimales",
  //       };
  //     }
  //     if (form.IngresoMensual !== "" && form.OtrosIngresos !== "") {
  //       if (
  //         Number(form.IngresoMensual) + Number(form.OtrosIngresos) !==
  //         Number(form.TotalIngreso)
  //       ) {
  //         return {
  //           error: true,
  //           seccion: 5,
  //           message:
  //             "El total de ingresos no coincide con el ingreso mensual y los otros ingresos",
  //         };
  //       }
  //     } else if (Number(form.IngresoMensual) !== Number(form.TotalIngreso)) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message: "El total de ingresos no coincide con el ingreso mensual ",
  //       };
  //     }
  //   }
  // } else {
  //   return {
  //     error: true,
  //     seccion: 5,
  //     message: "Debe agregar el ingreso total del solicitante",
  //   };
  // }

  // if (form.PersonasDependientes !== "") {
  //   if (isNaN(form.PersonasDependientes)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El numero de personas que dependen del ingreso no es válido",
  //     };
  //   } else {
  //     if (form.PersonasDependientes % 1 != 0) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message:
  //           "El numero de personas que dependen del ingreso no debe tener decimales",
  //       };
  //     }
  //   }
  // } else {
  //   return {
  //     error: true,
  //     seccion: 5,
  //     message:
  //       "Debe agregar el numero de personas que dependen del ingreso mensual",
  //   };
  // }

  // if (form.IngresoPercapita !== "") {
  //   if (isNaN(form.IngresoPercapita)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El ingreso mensual per cápita de dependientes no es válido",
  //     };
  //   } else {
  //     if (form.IngresoPercapita % 1 != 0) {
  //       return {
  //         error: true,
  //         seccion: 5,
  //         message: "El ingreso mensual per cápita no debe contener decimales",
  //       };
  //     }
  //   }
  // } else {
  //   return {
  //     error: true,
  //     seccion: 5,
  //     message: "Debe agregar el ingreso mensual per cápita de dependientes",
  //   };
  // }

  // if (
  //   form.Edad < 18 &&
  //   (form.idParentescoTutor === "" ||
  //     form.NombreTutor.trim() === "" ||
  //     form.PaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.FechaNacimientoTutor.trim() === "" ||
  //     form.EdadTutor === "" ||
  //     form.EdadTutor < 18 ||
  //     form.SexoTutor === "" ||
  //     form.idEntidadNacimientoTutor === "" ||
  //     form.CURPTutor.trim() === "" ||
  //     form.TelefonoTutor.trim() === "")
  // ) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "Uno o más datos obligatorios están vacíos o son incorrectos",
  //   };
  // }
  // if (form.CURPTutor.trim() !== "" && form.CURPTutor.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "La CURP debe contener 18 digitos",
  //   };
  // }
  // if (
  //   form.TelefonoTutor.trim() !== "" &&
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
  //     form.TelefonoTutor.trim().replace(/\D/g, "")
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Teléfono no valido" };
  // }
  // if (
  //   form.CorreoTutor.trim() !== "" &&
  //   !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
  //     form.CorreoTutor.trim()
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Correo no valido" };
  // }

  // if (form.Longitud.trim() != "") {
  //   if (isNaN(form.Longitud)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El formato de Longitud es inválido",
  //     };
  //   }
  // }

  if (form.Enlace.trim() === "" && form.idEnlace === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe agregar el enlace",
    };
  }

  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 7,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        //console.log("NF ==>", nameFile);
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element == 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar la identificación una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element == 2);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar el formato PVG una vez",
      };
    }

    let curp = form.ArchivosClasificacion.filter((element) => element == 4);
    if (curp.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar el CURP una vez",
      };
    }

    let tarjeta = form.ArchivosClasificacion.filter((element) => element == 13);
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar la Tarjeta Contigo Si una vez",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const ValesValidator = (form) => {
  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  return { error: false, seccion: -1, message: "" };
};

export const SolicitudValidatorAnt = (form) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 digitos",
    };
  }

  if (!/Q3450/i.test(form.Folio.trim())) {
    return { error: true, seccion: 0, message: "FOLIO NO VALIDO" };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del responsable de la solicitud",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido",
    };
  }

  if (
    form.FechaNacimiento === "" ||
    form.idEntidadNacimiento == "" ||
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  if (form.FechaINE !== "") {
    if (isNaN(form.FechaINE)) {
      return {
        error: true,
        seccion: 1,
        message:
          "El año de vencimiento de la Identificación Oficial no es válido",
      };
    }
    if (form.FechaINE.toString().length !== 4) {
      return {
        error: true,
        seccion: 1,
        message:
          "El año de vencimiento de la Identificación Oficial no es válido",
      };
    } else {
      let fecha_actual = new Date();
      let aa_actual = fecha_actual.getFullYear();
      //if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
      if (parseInt(form.FechaINE) < 2022) {
        return {
          error: true,
          seccion: 1,
          message: "La vigencia de la Identificación Oficial no es válida",
        };
      }
    }
  } else {
    return {
      error: true,
      seccion: 1,
      message:
        "Debe agregar la vigencia de la Identificación o cargar la Tarjeta Contigo Si con su clasificación correcta",
    };
  }

  if (
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" //||
    //form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.CPVive.toString().length !== 5) {
    return {
      error: true,
      seccion: 4,
      message: "El código postal debe ser de 5 dígitos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }

  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  // if (form.TelRecados.trim() !== "") {
  //   if (
  //     !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
  //       form.TelRecados.trim().replace(/\D/g, "")
  //     )
  //   ) {
  //     return {
  //       error: true,
  //       seccion: 2,
  //       message: "Teléfono de recados no valido",
  //     };
  //   }
  // }

  if (
    form.NecesidadSolicitante.trim() === "" ||
    form.CostoNecesidad === "" ||
    form.CostoNecesidad < 0
  ) {
    return {
      error: true,
      seccion: 3,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.Latitud.trim() != "") {
    if (isNaN(form.Latitud)) {
      return {
        error: true,
        seccion: 3,
        message: "El formato de Latitud es inválido",
      };
    }
  }

  if (form.IngresoMensual !== "") {
    if (isNaN(form.IngresoMensual)) {
      return {
        error: true,
        seccion: 5,
        message: "El ingreso mensual no es válido",
      };
    } else {
      if (form.IngresoMensual % 1 != 0) {
        return {
          error: true,
          seccion: 5,
          message: "El ingreso mensual no debe contener decimales",
        };
      }
    }
  } else {
    return {
      error: true,
      seccion: 5,
      message: "Debe agregar el ingreso mensual del solicitante",
    };
  }

  if (form.OtrosIngresos !== "") {
    if (isNaN(form.OtrosIngresos)) {
      return {
        error: true,
        seccion: 5,
        message: "Otros ingresos no es válido",
      };
    } else {
      if (form.OtrosIngresos % 1 != 0) {
        return {
          error: true,
          seccion: 5,
          message: "Los otros ingresos no debe contener decimales",
        };
      }
    }
  }

  if (form.TotalIngreso !== "") {
    if (isNaN(form.TotalIngreso)) {
      return {
        error: true,
        seccion: 5,
        message: "El total de ingresos mensual no es válido",
      };
    } else {
      if (form.TotalIngreso % 1 != 0) {
        return {
          error: true,
          seccion: 5,
          message: "El total de ingresos mensual no debe contener decimales",
        };
      }
      if (form.IngresoMensual !== "" && form.OtrosIngresos !== "") {
        if (
          Number(form.IngresoMensual) + Number(form.OtrosIngresos) !==
          Number(form.TotalIngreso)
        ) {
          return {
            error: true,
            seccion: 5,
            message:
              "El total de ingresos no coincide con el ingreso mensual y los otros ingresos",
          };
        }
      } else if (Number(form.IngresoMensual) !== Number(form.TotalIngreso)) {
        return {
          error: true,
          seccion: 5,
          message: "El total de ingresos no coincide con el ingreso mensual ",
        };
      }
    }
  } else {
    return {
      error: true,
      seccion: 5,
      message: "Debe agregar el ingreso total del solicitante",
    };
  }

  if (form.PersonasDependientes !== "") {
    if (isNaN(form.PersonasDependientes)) {
      return {
        error: true,
        seccion: 5,
        message: "El numero de personas que dependen del ingreso no es válido",
      };
    } else {
      if (form.PersonasDependientes % 1 != 0) {
        return {
          error: true,
          seccion: 5,
          message:
            "El numero de personas que dependen del ingreso no debe tener decimales",
        };
      }
    }
  } else {
    return {
      error: true,
      seccion: 5,
      message:
        "Debe agregar el numero de personas que dependen del ingreso mensual",
    };
  }

  if (form.IngresoPercapita !== "") {
    if (isNaN(form.IngresoPercapita)) {
      return {
        error: true,
        seccion: 5,
        message: "El ingreso mensual per cápita de dependientes no es válido",
      };
    } else {
      if (form.IngresoPercapita % 1 != 0) {
        return {
          error: true,
          seccion: 5,
          message: "El ingreso mensual per cápita no debe contener decimales",
        };
      }
    }
  } else {
    return {
      error: true,
      seccion: 5,
      message: "Debe agregar el ingreso mensual per cápita de dependientes",
    };
  }

  // if (
  //   form.Edad < 18 &&
  //   (form.idParentescoTutor === "" ||
  //     form.NombreTutor.trim() === "" ||
  //     form.PaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.FechaNacimientoTutor.trim() === "" ||
  //     form.EdadTutor === "" ||
  //     form.EdadTutor < 18 ||
  //     form.SexoTutor === "" ||
  //     form.idEntidadNacimientoTutor === "" ||
  //     form.CURPTutor.trim() === "" ||
  //     form.TelefonoTutor.trim() === "")
  // ) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "Uno o más datos obligatorios están vacíos o son incorrectos",
  //   };
  // }
  // if (form.CURPTutor.trim() !== "" && form.CURPTutor.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "La CURP debe contener 18 digitos",
  //   };
  // }
  // if (
  //   form.TelefonoTutor.trim() !== "" &&
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
  //     form.TelefonoTutor.trim().replace(/\D/g, "")
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Teléfono no valido" };
  // }
  // if (
  //   form.CorreoTutor.trim() !== "" &&
  //   !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
  //     form.CorreoTutor.trim()
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Correo no valido" };
  // }

  // if (form.Longitud.trim() != "") {
  //   if (isNaN(form.Longitud)) {
  //     return {
  //       error: true,
  //       seccion: 5,
  //       message: "El formato de Longitud es inválido",
  //     };
  //   }
  // }

  if (form.Enlace.trim() === "" && form.idEnlace === "") {
    return {
      error: true,
      seccion: 6,
      message: "Debe agregar el enlace",
    };
  }

  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 7,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        //console.log("NF ==>", nameFile);
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element == 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar la identificación una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element == 2);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar el formato PVG una vez",
      };
    }
    let tarjeta = form.ArchivosClasificacion.filter((element) => element == 5);
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 7,
        message: "Solo puede cargar el acuse una vez",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const CedulaValidator = (form, necesitaTutor) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }

  if (
    form.idEntidadVive === "" ||
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" ||
    form.NoIntVive.trim() === "" ||
    form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.TotalHogares === "" ||
    form.TotalHogares < 0 ||
    form.NumeroMujeresHogar === "" ||
    form.NumeroMujeresHogar < 0 ||
    form.NumeroHombresHogar === "" ||
    form.NumeroHombresHogar < 0
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos o son menores a 0",
    };
  }
  if (form.NumeroMujeresHogar == 0 && form.NumeroHombresHogar == 0) {
    return {
      error: true,
      seccion: 5,
      message: "Debe haber por lo menos una persona en el hogar",
    };
  }
  if (
    form.AtencionesMedicas.length < 1 ||
    form.Enfermedades.length < 1 ||
    form.DificultadMovilidad === "" ||
    form.DificultadVer === "" ||
    form.DificultadHablar === "" ||
    form.DificultadOir === "" ||
    form.DificultadVestirse === "" ||
    form.DificultadRecordar === "" ||
    form.DificultadBrazos === "" ||
    form.DificultadMental === ""
  ) {
    return {
      error: true,
      seccion: 6,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.AsisteEscuela < 0 ||
    form.idNivelEscuela === "" ||
    form.idGradoEscuela === ""
  ) {
    return {
      error: true,
      seccion: 7,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.idActividades === "" ||
    form.Prestaciones.length < 1 ||
    form.IngresoTotalMesPasado === "" ||
    form.IngresoTotalMesPasado < 0 ||
    form.PensionMensual === "" ||
    form.PensionMensual < 0 ||
    form.IngresoOtrosPaises === "" ||
    form.IngresoOtrosPaises < 0
  ) {
    return {
      error: true,
      seccion: 8,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.GastoAlimentos === "" ||
    form.GastoAlimentos < 0 ||
    form.PeriodicidadAlimentos === "" ||
    form.GastoVestido === "" ||
    form.GastoVestido < 0 ||
    form.PeriodicidadVestido === "" ||
    form.GastoEducacion === "" ||
    form.GastoEducacion < 0 ||
    form.PeriodicidadEducacion === "" ||
    form.GastoMedicinas === "" ||
    form.GastoMedicinas < 0 ||
    form.PeriodicidadMedicinas === "" ||
    form.GastosConsultas === "" ||
    form.GastosConsultas < 0 ||
    form.PeriodicidadConsultas === "" ||
    form.GastosCombustibles === "" ||
    form.GastosCombustibles < 0 ||
    form.PeriodicidadCombustibles === "" ||
    form.GastosServiciosBasicos === "" ||
    form.GastosServiciosBasicos < 0 ||
    form.PeriodicidadServiciosBasicos === "" ||
    form.GastosServiciosRecreacion === "" ||
    form.GastosServiciosRecreacion < 0 ||
    form.PeriodicidadServiciosRecreacion === ""
  ) {
    return {
      error: true,
      seccion: 9,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.PersonasHogar === "" ||
    form.PersonasHogar < 1 ||
    form.CuartosHogar === "" ||
    form.CuartosHogar < 0 ||
    form.idTipoPiso === "" ||
    form.idTipoParedes === "" ||
    form.idTipoTecho === "" ||
    form.idTipoAgua === "" ||
    form.idTipoDrenaje === "" ||
    form.idTipoLuz === "" ||
    form.idTipoCombustible === ""
  ) {
    return {
      error: true,
      seccion: 11,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.idTipoCombustibleAgua == "") {
    return {
      error: true,
      seccion: 12,
      message: "Uno o más campos obligatorios estan vacíos",
    };
  }
  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 13,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 13,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }

    let ine = form.ArchivosClasificacion.filter((element) => element == 3);
    if (ine.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue la identificación oficial solo una vez",
      };
    }

    let pvg = form.ArchivosClasificacion.filter((element) => element == 4);
    if (pvg.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue el CURP solo una vez",
      };
    }

    let curp = form.ArchivosClasificacion.filter((element) => element == 6);
    if (curp.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue el comprobante de domicilio solo una vez",
      };
    }

    let acuse = form.ArchivosClasificacion.filter((element) => element == 5);
    if (acuse.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue el acuse solo una vez",
      };
    }

    let evidencia = form.ArchivosClasificacion.filter(
      (element) => element == 12
    );
    if (evidencia.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue las evidencias PDF solo una vez",
      };
    }

    let formato = form.ArchivosClasificacion.filter((element) => element == 10);
    if (formato.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue el formato del programa solo una vez",
      };
    }

    let tarjeta = form.ArchivosClasificacion.filter((element) => element == 13);
    if (tarjeta.length > 1) {
      return {
        error: true,
        seccion: 13,
        message: "Cargue la tarjeta Contigo Si solo una vez",
      };
    }
  }

  if (form.Enlace === "") {
    return {
      error: true,
      seccion: 14,
      message: "Ingrese el enlace",
    };
  }
  return { error: false, seccion: -1, message: "" };
};

export const CedulaValidatorProyectos = (form) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 digitos",
    };
  }
  if (
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  if (
    form.idEntidadVive === "" ||
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" ||
    form.NoIntVive.trim() === "" ||
    form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.TotalHogares === "" ||
    form.TotalHogares < 0 ||
    form.NumeroMujeresHogar === "" ||
    form.NumeroMujeresHogar < 0 ||
    form.NumeroHombresHogar === "" ||
    form.NumeroHombresHogar < 0
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos o son menores a 0",
    };
  }
  if (form.NumeroMujeresHogar == 0 && form.NumeroHombresHogar == 0) {
    return {
      error: true,
      seccion: 5,
      message: "Debe haber por lo menos una persona en el hogar",
    };
  }
  if (form.AtencionesMedicas.length < 1) {
    return {
      error: true,
      seccion: 6,
      message:
        "Debe seleccionar a que institución esta afiliado el solicitante",
    };
  }
  if (form.Enfermedades.length < 1) {
    return {
      error: true,
      seccion: 6,
      message: "Debe indicar si el solititante tiene alguna enfermedad",
    };
  }
  if (
    form.DificultadMovilidad === "" ||
    form.DificultadVer === "" ||
    form.DificultadHablar === "" ||
    form.DificultadOir === "" ||
    form.DificultadVestirse === "" ||
    form.DificultadRecordar === "" ||
    form.DificultadBrazos === "" ||
    form.DificultadMental === ""
  ) {
    return {
      error: true,
      seccion: 6,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.AsisteEscuela < 0 ||
    form.idNivelEscuela === "" ||
    form.idGradoEscuela === ""
  ) {
    return {
      error: true,
      seccion: 7,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.idActividades === "" ||
    form.Prestaciones.length < 1 ||
    form.IngresoTotalMesPasado === "" ||
    form.IngresoTotalMesPasado < 0 ||
    form.PensionMensual === "" ||
    form.PensionMensual < 0 ||
    form.IngresoOtrosPaises === "" ||
    form.IngresoOtrosPaises < 0
  ) {
    return {
      error: true,
      seccion: 8,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.GastoAlimentos === "" ||
    form.GastoAlimentos < 0 ||
    form.PeriodicidadAlimentos === "" ||
    form.GastoVestido === "" ||
    form.GastoVestido < 0 ||
    form.PeriodicidadVestido === "" ||
    form.GastoEducacion === "" ||
    form.GastoEducacion < 0 ||
    form.PeriodicidadEducacion === "" ||
    form.GastoMedicinas === "" ||
    form.GastoMedicinas < 0 ||
    form.PeriodicidadMedicinas === "" ||
    form.GastosConsultas === "" ||
    form.GastosConsultas < 0 ||
    form.PeriodicidadConsultas === "" ||
    form.GastosCombustibles === "" ||
    form.GastosCombustibles < 0 ||
    form.PeriodicidadCombustibles === "" ||
    form.GastosServiciosBasicos === "" ||
    form.GastosServiciosBasicos < 0 ||
    form.PeriodicidadServiciosBasicos === "" ||
    form.GastosServiciosRecreacion === "" ||
    form.GastosServiciosRecreacion < 0 ||
    form.PeriodicidadServiciosRecreacion === ""
  ) {
    return {
      error: true,
      seccion: 9,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.PersonasHogar === "" ||
    form.PersonasHogar < 1 ||
    form.CuartosHogar === "" ||
    form.CuartosHogar < 0 ||
    form.idTipoPiso === "" ||
    form.idTipoParedes === "" ||
    form.idTipoTecho === "" ||
    form.idTipoAgua === "" ||
    form.idTipoDrenaje === "" ||
    form.idTipoLuz === "" ||
    form.idTipoCombustible === ""
  ) {
    return {
      error: true,
      seccion: 11,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.idTipoCombustibleAgua == "") {
    return {
      error: true,
      seccion: 12,
      message: "Uno o más campos obligatorios estan vacíos",
    };
  }
  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 13,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  return { error: false, seccion: -1, message: "" };
};

export const CedulaValidatorDiagnostico = (form) => {
  if (form.FechaSolicitud.trim() === "" || form.CURP.trim() === "") {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.EntidadNacimiento === "") {
    return {
      error: true,
      seccion: 1,
      message: "Debe agregar la entidad de nacimiento del Solicitante",
    };
  }
  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  if (
    form.idEntidadVive === "" ||
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" ||
    form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.TotalHogares === "" ||
    form.TotalHogares < 0 ||
    form.NumeroMujeresHogar === "" ||
    form.NumeroMujeresHogar < 0 ||
    form.NumeroHombresHogar === "" ||
    form.NumeroHombresHogar < 0
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos o son menores a 0",
    };
  }
  if (form.NumeroMujeresHogar == 0 && form.NumeroHombresHogar == 0) {
    return {
      error: true,
      seccion: 5,
      message: "Debe haber por lo menos una persona en el hogar",
    };
  }
  if (form.AtencionesMedicas.length < 1) {
    return {
      error: true,
      seccion: 6,
      message:
        "Debe seleccionar a que institución esta afiliado el solicitante",
    };
  }
  if (form.Enfermedades.length < 1) {
    return {
      error: true,
      seccion: 6,
      message: "Debe indicar si el solititante tiene alguna enfermedad",
    };
  }
  if (
    form.DificultadMovilidad === "" ||
    form.DificultadVer === "" ||
    form.DificultadHablar === "" ||
    form.DificultadOir === "" ||
    form.DificultadVestirse === "" ||
    form.DificultadRecordar === "" ||
    form.DificultadBrazos === "" ||
    form.DificultadMental === ""
  ) {
    return {
      error: true,
      seccion: 6,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.AsisteEscuela < 0 ||
    form.idNivelEscuela === "" ||
    form.idGradoEscuela === ""
  ) {
    return {
      error: true,
      seccion: 7,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.idActividades === "" ||
    form.Prestaciones.length < 1 ||
    form.IngresoTotalMesPasado === "" ||
    form.IngresoTotalMesPasado < 0 ||
    form.PensionMensual === "" ||
    form.PensionMensual < 0 ||
    form.IngresoOtrosPaises === "" ||
    form.IngresoOtrosPaises < 0
  ) {
    return {
      error: true,
      seccion: 8,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.GastoAlimentos === "" ||
    form.GastoAlimentos < 0 ||
    form.PeriodicidadAlimentos === "" ||
    form.GastoVestido === "" ||
    form.GastoVestido < 0 ||
    form.PeriodicidadVestido === "" ||
    form.GastoEducacion === "" ||
    form.GastoEducacion < 0 ||
    form.PeriodicidadEducacion === "" ||
    form.GastoMedicinas === "" ||
    form.GastoMedicinas < 0 ||
    form.PeriodicidadMedicinas === "" ||
    form.GastosConsultas === "" ||
    form.GastosConsultas < 0 ||
    form.PeriodicidadConsultas === "" ||
    form.GastosCombustibles === "" ||
    form.GastosCombustibles < 0 ||
    form.PeriodicidadCombustibles === "" ||
    form.GastosServiciosBasicos === "" ||
    form.GastosServiciosBasicos < 0 ||
    form.PeriodicidadServiciosBasicos === "" ||
    form.GastosServiciosRecreacion === "" ||
    form.GastosServiciosRecreacion < 0 ||
    form.PeriodicidadServiciosRecreacion === ""
  ) {
    return {
      error: true,
      seccion: 9,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.PersonasHogar === "" ||
    form.PersonasHogar < 1 ||
    form.CuartosHogar === "" ||
    form.CuartosHogar < 0 ||
    form.idTipoPiso === "" ||
    form.idTipoParedes === "" ||
    form.idTipoTecho === "" ||
    form.idTipoAgua === "" ||
    form.idTipoDrenaje === "" ||
    form.idTipoLuz === "" ||
    form.idTipoCombustible === ""
  ) {
    return {
      error: true,
      seccion: 11,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.idTipoCombustibleAgua == "") {
    return {
      error: true,
      seccion: 12,
      message: "Uno o más campos obligatorios estan vacíos",
    };
  }
  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 13,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  return { error: false, seccion: -1, message: "" };
};

export const CedulaValidatorYoPuedo = (form, necesitaTutor) => {
  if (
    form.FechaSolicitud.trim() === "" ||
    form.CURP.trim() === "" ||
    form.Folio.trim() === ""
  ) {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (form.Folio.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "El Folio debe contener 18 digitos",
    };
  }
  if (
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.idEntidadNacimiento === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0 ||
    form.TarjetaImpulso === "" ||
    form.idSituacionActual === ""
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  if (form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 2,
      message: "Debe agregar un numero de celular",
    };
  }
  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  if (
    (form.Edad < 18 || necesitaTutor === 1) &&
    (form.idParentescoTutor === "" ||
      form.NombreTutor.trim() === "" ||
      form.PaternoTutor.trim() === "" ||
      form.MaternoTutor.trim() === "" ||
      form.MaternoTutor.trim() === "" ||
      form.FechaNacimientoTutor.trim() === "" ||
      form.EdadTutor === "" ||
      form.EdadTutor < 18 ||
      form.SexoTutor === "" ||
      form.idEntidadNacimientoTutor === "" ||
      form.CURPTutor.trim() === "" ||
      form.TelefonoTutor.trim() === "")
  ) {
    return {
      error: true,
      seccion: 3,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }
  if (form.CURPTutor.trim() !== "" && form.CURPTutor.trim().length !== 18) {
    return {
      error: true,
      seccion: 3,
      message: "La CURP debe contener 18 digitos",
    };
  }
  if (
    form.TelefonoTutor.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelefonoTutor.trim().replace(/\D/g, "")
    )
  ) {
    return { error: true, seccion: 3, message: "Teléfono no valido" };
  }
  if (
    form.CorreoTutor.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.CorreoTutor.trim()
    )
  ) {
    return { error: true, seccion: 3, message: "Correo no valido" };
  }
  if (
    form.idEntidadVive === "" ||
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" ||
    form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.Edad > 17) {
    if (
      form.TotalHogares === "" ||
      form.TotalHogares < 0 ||
      form.NumeroMujeresHogar === "" ||
      form.NumeroMujeresHogar < 0 ||
      form.NumeroHombresHogar === "" ||
      form.NumeroHombresHogar < 0
    ) {
      return {
        error: true,
        seccion: 5,
        message: "Uno o más datos obligatorios están vacíos o son menores a 0",
      };
    }
    if (form.NumeroMujeresHogar == 0 && form.NumeroHombresHogar == 0) {
      return {
        error: true,
        seccion: 5,
        message: "Debe haber por lo menos una persona en el hogar",
      };
    }
    if (
      form.AtencionesMedicas.length < 1 ||
      form.Enfermedades.length < 1 ||
      form.DificultadMovilidad === "" ||
      form.DificultadVer === "" ||
      form.DificultadHablar === "" ||
      form.DificultadOir === "" ||
      form.DificultadVestirse === "" ||
      form.DificultadRecordar === "" ||
      form.DificultadBrazos === "" ||
      form.DificultadMental === ""
    ) {
      return {
        error: true,
        seccion: 6,
        message: "Uno o más datos obligatorios están vacíos",
      };
    }
    if (
      form.AsisteEscuela === "" ||
      form.idNivelEscuela === "" ||
      form.idGradoEscuela === ""
    ) {
      return {
        error: true,
        seccion: 7,
        message: "Uno o más datos obligatorios están vacíos",
      };
    }
    if (
      form.idActividades === "" ||
      form.Prestaciones.length < 1 ||
      form.IngresoTotalMesPasado === "" ||
      form.IngresoTotalMesPasado < 0 ||
      form.PensionMensual === "" ||
      form.PensionMensual < 0 ||
      form.IngresoOtrosPaises === "" ||
      form.IngresoOtrosPaises < 0
    ) {
      return {
        error: true,
        seccion: 8,
        message: "Uno o más datos obligatorios están vacíos",
      };
    }
    if (
      form.GastoAlimentos === "" ||
      form.GastoAlimentos < 0 ||
      form.PeriodicidadAlimentos === "" ||
      form.GastoVestido === "" ||
      form.GastoVestido < 0 ||
      form.PeriodicidadVestido === "" ||
      form.GastoEducacion === "" ||
      form.GastoEducacion < 0 ||
      form.PeriodicidadEducacion === "" ||
      form.GastoMedicinas === "" ||
      form.GastoMedicinas < 0 ||
      form.PeriodicidadMedicinas === "" ||
      form.GastosConsultas === "" ||
      form.GastosConsultas < 0 ||
      form.PeriodicidadConsultas === "" ||
      form.GastosCombustibles === "" ||
      form.GastosCombustibles < 0 ||
      form.PeriodicidadCombustibles === "" ||
      form.GastosServiciosBasicos === "" ||
      form.GastosServiciosBasicos < 0 ||
      form.PeriodicidadServiciosBasicos === "" ||
      form.GastosServiciosRecreacion === "" ||
      form.GastosServiciosRecreacion < 0 ||
      form.PeriodicidadServiciosRecreacion === ""
    ) {
      return {
        error: true,
        seccion: 9,
        message: "Uno o más datos obligatorios están vacíos",
      };
    }
    if (
      form.PersonasHogar === "" ||
      form.PersonasHogar < 1 ||
      form.CuartosHogar === "" ||
      form.CuartosHogar < 0 ||
      form.idTipoPiso === "" ||
      form.idTipoParedes === "" ||
      form.idTipoTecho === "" ||
      form.idTipoAgua === "" ||
      form.idTipoDrenaje === "" ||
      form.idTipoLuz === "" ||
      form.idTipoCombustible === ""
    ) {
      return {
        error: true,
        seccion: 11,
        message: "Uno o más datos obligatorios están vacíos",
      };
    }
  }
  if (form.idEnlace === "") {
    return {
      error: true,
      seccion: 13,
      message: "Debe seleccionar el enlace",
    };
  }
  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 16,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 16,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const CedulaValidatorDiagnosticos = (form, necesitaTutor) => {
  if (form.FechaSolicitud.trim() === "" || form.CURP.trim() === "") {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (form.CURP.trim().length !== 18) {
    return {
      error: true,
      seccion: 0,
      message: "La CURP debe contener 18 digitos",
    };
  }
  // if (form.Folio.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 0,
  //     message: "El Folio debe contener 18 digitos",
  //   };
  // }
  if (
    form.Nombre.trim() === "" ||
    form.Paterno.trim() === "" ||
    form.Materno.trim() === "" ||
    form.FechaNacimiento.trim() === "" ||
    form.idEntidadNacimiento === "" ||
    form.Edad === "" ||
    form.Edad < 1 ||
    form.idEstadoCivil === "" ||
    form.idSituacionActual === "" ||
    form.idParentescoJefeHogar === "" ||
    form.NumHijos === "" ||
    form.NumHijos < 0 ||
    form.NumHijas === "" ||
    form.NumHijas < 0 ||
    form.TarjetaImpulso === "" ||
    form.idSituacionActual === ""
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  // if (form.Celular.trim() === "") {
  //   return {
  //     error: true,
  //     seccion: 2,
  //     message: "Debe agregar un numero de celular",
  //   };
  // }
  if (
    form.Celular.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Celular no valido" };
  }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 2, message: "Correo no valido" };
  }
  if (
    form.Telefono.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  ) {
    return { error: true, seccion: 2, message: "Teléfono fijo no valido" };
  }
  if (
    form.TelRecados.trim() !== "" &&
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
      form.TelRecados.trim().replace(/\D/g, "")
    )
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Teléfono de recados no valido",
    };
  }
  // if (
  //   (form.Edad < 18 || necesitaTutor === 1) &&
  //   (form.idParentescoTutor === "" ||
  //     form.NombreTutor.trim() === "" ||
  //     form.PaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.MaternoTutor.trim() === "" ||
  //     form.FechaNacimientoTutor.trim() === "" ||
  //     form.EdadTutor === "" ||
  //     form.EdadTutor < 18 ||
  //     form.SexoTutor === "" ||
  //     form.idEntidadNacimientoTutor === "" ||
  //     form.CURPTutor.trim() === "" ||
  //     form.TelefonoTutor.trim() === "")
  // ) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "Uno o más datos obligatorios están vacíos o son incorrectos",
  //   };
  // }
  // if (form.CURPTutor.trim() !== "" && form.CURPTutor.trim().length !== 18) {
  //   return {
  //     error: true,
  //     seccion: 3,
  //     message: "La CURP debe contener 18 digitos",
  //   };
  // }
  // if (
  //   form.TelefonoTutor.trim() !== "" &&
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(
  //     form.TelefonoTutor.trim().replace(/\D/g, "")
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Teléfono no valido" };
  // }
  // if (
  //   form.CorreoTutor.trim() !== "" &&
  //   !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
  //     form.CorreoTutor.trim()
  //   )
  // ) {
  //   return { error: true, seccion: 3, message: "Correo no valido" };
  // }
  if (
    form.idEntidadVive === "" ||
    form.MunicipioVive === "" ||
    form.LocalidadVive === "" ||
    form.CPVive === "" ||
    form.ColoniaVive.trim() === "" ||
    form.CalleVive.trim() === "" ||
    form.NoExtVive.trim() === "" ||
    form.Referencias.trim() === ""
  ) {
    return {
      error: true,
      seccion: 4,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (
    form.TotalHogares === "" ||
    form.TotalHogares < 0 ||
    form.NumeroMujeresHogar === "" ||
    form.NumeroMujeresHogar < 0 ||
    form.NumeroHombresHogar === "" ||
    form.NumeroHombresHogar < 0
  ) {
    return {
      error: true,
      seccion: 5,
      message: "Uno o más datos obligatorios están vacíos o son menores a 0",
    };
  }
  if (form.NumeroMujeresHogar == 0 && form.NumeroHombresHogar == 0) {
    return {
      error: true,
      seccion: 5,
      message: "Debe haber por lo menos una persona en el hogar",
    };
  }
  if (
    form.AtencionesMedicas.length < 1 ||
    form.Enfermedades.length < 1 ||
    form.DificultadMovilidad === "" ||
    form.DificultadVer === "" ||
    form.DificultadHablar === "" ||
    form.DificultadOir === "" ||
    form.DificultadVestirse === "" ||
    form.DificultadRecordar === "" ||
    form.DificultadBrazos === "" ||
    form.DificultadMental === ""
  ) {
    return {
      error: true,
      seccion: 6,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.AsisteEscuela === "" ||
    form.idNivelEscuela === "" ||
    form.idGradoEscuela === ""
  ) {
    return {
      error: true,
      seccion: 7,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.idActividades === "" ||
    form.Prestaciones.length < 1 ||
    form.IngresoTotalMesPasado === "" ||
    form.IngresoTotalMesPasado < 0 ||
    form.PensionMensual === "" ||
    form.PensionMensual < 0 ||
    form.IngresoOtrosPaises === "" ||
    form.IngresoOtrosPaises < 0
  ) {
    return {
      error: true,
      seccion: 8,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.GastoAlimentos === "" ||
    form.GastoAlimentos < 0 ||
    form.PeriodicidadAlimentos === "" ||
    form.GastoVestido === "" ||
    form.GastoVestido < 0 ||
    form.PeriodicidadVestido === "" ||
    form.GastoEducacion === "" ||
    form.GastoEducacion < 0 ||
    form.PeriodicidadEducacion === "" ||
    form.GastoMedicinas === "" ||
    form.GastoMedicinas < 0 ||
    form.PeriodicidadMedicinas === "" ||
    form.GastosConsultas === "" ||
    form.GastosConsultas < 0 ||
    form.PeriodicidadConsultas === "" ||
    form.GastosCombustibles === "" ||
    form.GastosCombustibles < 0 ||
    form.PeriodicidadCombustibles === "" ||
    form.GastosServiciosBasicos === "" ||
    form.GastosServiciosBasicos < 0 ||
    form.PeriodicidadServiciosBasicos === "" ||
    form.GastosServiciosRecreacion === "" ||
    form.GastosServiciosRecreacion < 0 ||
    form.PeriodicidadServiciosRecreacion === ""
  ) {
    return {
      error: true,
      seccion: 9,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }
  if (
    form.PersonasHogar === "" ||
    form.PersonasHogar < 1 ||
    form.CuartosHogar === "" ||
    form.CuartosHogar < 0 ||
    form.idTipoPiso === "" ||
    form.idTipoParedes === "" ||
    form.idTipoTecho === "" ||
    form.idTipoAgua === "" ||
    form.idTipoDrenaje === "" ||
    form.idTipoLuz === "" ||
    form.idTipoCombustible === ""
  ) {
    return {
      error: true,
      seccion: 12,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  // if (form.idEnlace === "") {
  //   return {
  //     error: true,
  //     seccion: 14,
  //     message: "Debe seleccionar el enlace",
  //   };
  // }
  if (form.Files.length > form.ArchivosClasificacion.length) {
    return {
      error: true,
      seccion: 15,
      message: "Todos los archivos deben tener clasificación",
    };
  }

  if (form.Files.length > 0) {
    let flag = false;
    form.Files.map((file) => {
      let nameFile = file.name.toString();
      if (/(.docx|.doc|.xlsx|.xls|.number)$/i.test(nameFile)) {
        flag = true;
      }
    });
    if (flag) {
      return {
        error: true,
        seccion: 15,
        message: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
      };
    }
  }

  return { error: false, seccion: -1, message: "" };
};

export const EncuestaValidator = (form) => {
  if (form.idTipoApoyo === 0 || form.Fecha === "") {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (!form.idCGCSI) {
    return {
      error: true,
      seccion: 0,
      message: "Debe seleccionar la ubicación",
    };
  }

  if (form.idCGCSI < 0 && form.Ubicacion === "") {
    return {
      error: true,
      seccion: 0,
      message: "Debe agregar una referencia de la ubicación",
    };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del Beneficiario",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido del Beneficiario",
    };
  }

  if (form.idMunicipio === 0 || form.idLocalidad === 0 || form.Colonia === "") {
    return {
      error: true,
      seccion: 1,
      message: "Uno o más datos obligatorios están vacíos o son incorrectos",
    };
  }

  // if (form.Celular && form.Celular.trim() === "") {
  //   return {
  //     error: true,
  //     seccion: 1,
  //     message: "Debe agregar un numero de celular",
  //   };
  // }

  // if (
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  // ) {
  //   return { error: true, seccion: 1, message: "Celular no valido" };
  // }
  // console.log(form.Telefono);
  // if (
  //   form.Telefono &&
  //   form.Telefono.trim() !== "" &&
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  // ) {
  //   return { error: true, seccion: 1, message: "Teléfono fijo no valido" };
  // }

  if (
    form.q1 === 0 ||
    form.q2 === 0 ||
    form.q3 === "" ||
    form.q4 === 0 ||
    form.q5 === 0 ||
    form.q6 === 0 ||
    form.q7 === 0 ||
    form.q9 === 0 ||
    form.q10 === 0 ||
    form.q11 === 0 ||
    form.q12 === "" ||
    form.q17 === ""
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  return { error: false, seccion: -1, message: "" };
};

export const EncuestaTCSValidator = (form) => {
  if (form.idTipoApoyo === 0 || form.Fecha === "") {
    return {
      error: true,
      seccion: 0,
      message: "Uno o más datos obligatorios están vacíos",
    };
  }

  if (form.Nombre.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el nombre del Beneficiario",
    };
  }

  if (form.Paterno.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Agregue el primer apellido del Beneficiario",
    };
  }

  if (
    form.Municipio === "" ||
    form.Localidad === "" ||
    form.Colonia === "" ||
    form.Calle === "" ||
    form.NumExt === ""
  ) {
    return {
      error: true,
      seccion: 1,
      message:
        "Uno o más datos obligatorios de la dirección están vacíos o son incorrectos",
    };
  }

  if (form.CP && form.CP.length !== 5) {
    return {
      error: true,
      seccion: 1,
      message: "El código postal debe contener 5 digitos",
    };
  }

  if (form.CP && !/^\d+$/.test(form.CP)) {
    return {
      error: true,
      seccion: 1,
      message: "El código postal debe contener solo números",
    };
  }

  if (form.Celular && form.Celular.trim() === "") {
    return {
      error: true,
      seccion: 1,
      message: "Debe agregar un numero de celular",
    };
  }

  if (form.Celular.length !== 10) {
    return {
      error: true,
      seccion: 1,
      message: "El número de celular debe contener 10 digitos",
    };
  }

  if (
    !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Celular.trim().replace(/\D/g, ""))
  ) {
    return {
      error: true,
      seccion: 1,
      message: "Celular no valido",
    };
  }

  // console.log(form.Telefono);
  // if (
  //   form.Telefono &&
  //   form.Telefono.trim() !== "" &&
  //   !/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.Telefono.trim().replace(/\D/g, ""))
  // ) {
  //   return { error: true, seccion: 1, message: "Teléfono fijo no valido" };
  // }
  if (
    form.Correo.trim() !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo.trim()
    )
  ) {
    return { error: true, seccion: 1, message: "Correo no valido" };
  }

  if (
    form.q19 === 0 ||
    form.q20.trim() === "" ||
    form.q21 === 0 ||
    form.q23.trim() === "" ||
    form.q24.trim() === ""
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Una o más preguntas obligatorias están vacías",
    };
  }

  if (
    !form.q22.r1 &&
    !form.q22.r2 &&
    !form.q22.r3 &&
    !form.q22.r4 &&
    !form.q22.r5 &&
    !form.q22.r6 &&
    !form.q22.r7 &&
    !form.q22.r8 &&
    !form.q22.r9 &&
    form.q22.r10.trim().length === 0
  ) {
    return {
      error: true,
      seccion: 2,
      message: "Debe seleccionar al menos un beneficio adicional",
    };
  }

  return { error: false, seccion: -1, message: "" };
};
